import React from "react";
import {
  Stack,
  Grid,
  Typography,
  Box,
  CardActionArea,
  styled,
} from "@mui/material";
import logoRefsa from "../../img/Refsa_logo.svg";
import logoPlan from "../../img/plan estrategico.svg";
import logoRapidatos from "../../img/Rapidatos logo.svg";
import logoApiAuth from "../../img/API AUTH.svg";
import logoFirma from "../../img/firma digital.svg";
import logoAsistencia from "../../img/asistencia.svg";

interface Props {
  status: any;
}

export const ItemPageWeb = ({ status }: Props) => {
  interface Pod {
    objectMeta: {
      labels: {
        [key: string]: string;
      };
      creationTimestamp: string;
    };
    status: string;
    restartCount: number;
    containerImages: string[];
  }

  interface APIResponse {
    list: {
      listMeta: {
        totalItems: number;
      };
      status: {
        running: number;
        pending: number;
        failed: number;
        succeeded: number;
        unknown: number;
        terminating: number;
      };
      pods: Pod[];
    };
  }

  // Función para filtrar los pods por una etiqueta específica
  const filterPodsByLabel = (labelKey: string, labelValue: string) => {
    const filteredPods = status.list.pods.filter(
      (pod: Pod) => pod.objectMeta.labels[labelKey] === labelValue
    );
    return filteredPods.map((pod: Pod) => ({
      status: pod.status,
      restartCount: pod.restartCount,
    }));
  };

  // Ejemplo: Filtrar los pods con la label "k8s-app" igual a "api-planestrategico"
  const filter = (name: string) => {
    return filterPodsByLabel("k8s-app", name);
  };

  return (
    <>
      <Grid container display={"flex"}>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          xl={3}
          minHeight={"40vh"}
          display={"flex"}
          justifyContent={"center"}
        >
          <Grid container>
            <a href="https://api.formosa.gob.ar/api-refsa/api">
              <Stack
                spacing={1}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                width={"100%"}
                p={5}
                flexWrap={"wrap"}
              >
                <Box maxWidth={"80px"} maxHeight={"80px"}>
                  <img src={logoRefsa} width={"80px"} height={"80px"} />
                </Box>
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#14672E",
                  }}
                >
                  REFSA TELECOMUNICACIONES
                </Typography>
                <Typography
                  style={{
                    textAlign: "justify",
                    fontSize: "1rem",
                    color: "#7D995C",
                    minHeight: "13vh",
                  }}
                >
                  Software para gestión de tickets que maneja clientes, tickets,
                  recursos y datos, con opciones para agregar, editar, eliminar
                  y obtener información detallada.
                </Typography>
              </Stack>
              <Stack p={5}>
                {filter("api-refsa").map((podData: any, index: any) => (
                  <>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: "0.9rem",
                        color: "#14672E",
                      }}
                    >
                      Estado: {podData.status}
                    </Typography>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: "0.9rem",
                        color: "#14672E",
                      }}
                    >
                      Reinicios: {podData.restartCount}
                    </Typography>
                  </>
                ))}
              </Stack>
            </a>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          xl={3}
          minHeight={"40vh"}
          display={"flex"}
          justifyContent={"center"}
        >
          <a href="https://api.formosa.gob.ar/api-planestrategico/api/">
            <Grid container>
              <Stack
                spacing={1}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                width={"100%"}
                p={5}
                flexWrap={"wrap"}
              >
                <Box maxWidth={"80px"} maxHeight={"80px"}>
                  <img src={logoPlan} width={"80px"} />
                </Box>
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#14672E",
                  }}
                >
                  PLAN ESTRATÉGICO
                </Typography>
                <Typography
                  style={{
                    textAlign: "justify",
                    fontSize: "1rem",
                    color: "#7D995C",
                    minHeight: "13vh",
                  }}
                >
                  Herramienta de creación de planes de acción con datos
                  específicos, objetivos y metas, para compartir planes
                  estratégicos de manera sencilla y efectiva.
                </Typography>
              </Stack>
              <Stack p={5}>
                {filter("api-planestrategico").map(
                  (podData: any, index: any) => (
                    <>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontSize: "0.9rem",
                          color: "#14672E",
                        }}
                      >
                        Estado: {podData.status}
                      </Typography>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontSize: "0.9rem",
                          color: "#14672E",
                        }}
                      >
                        Reinicios: {podData.restartCount}
                      </Typography>
                    </>
                  )
                )}
              </Stack>
            </Grid>
          </a>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          xl={3}
          minHeight={"40vh"}
          display={"flex"}
          justifyContent={"center"}
        >
          <a href="https://api.formosa.gob.ar/api-rapidatos/api/">
            <Grid container>
              <Stack
                spacing={1}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                width={"100%"}
                p={5}
                flexWrap={"wrap"}
              >
                <Box maxWidth={"80px"} maxHeight={"80px"}>
                  <img src={logoRapidatos} width={"80px"} height={"80px"} />
                </Box>
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#14672E",
                  }}
                >
                  RAPIDATOS
                </Typography>
                <Typography
                  style={{
                    textAlign: "justify",
                    fontSize: "1rem",
                    color: "#7D995C",
                    minHeight: "13vh",
                  }}
                >
                  Este sitio proporciona información sobre instituciones
                  educativas y docentes, con datos sobre establecimientos
                  educativos, docentes y alumnos en diferentes niveles
                  educativos, y estadísticas sobre el total de docentes y
                  establecimientos educativos.
                </Typography>
              </Stack>
              <Stack p={5}>
                {filter("api-rapidatos").map((podData: any, index: any) => (
                  <>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: "0.9rem",
                        color: "#14672E",
                      }}
                    >
                      Estado: {podData.status}
                    </Typography>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: "0.9rem",
                        color: "#14672E",
                      }}
                    >
                      Reinicios: {podData.restartCount}
                    </Typography>
                  </>
                ))}
              </Stack>
            </Grid>
          </a>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          xl={3}
          minHeight={"40vh"}
          display={"flex"}
          justifyContent={"center"}
        >
          <a href="https://api.formosa.gob.ar/api-asistencia/api/">
            <Grid container>
              <Stack
                spacing={1}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                width={"100%"}
                p={5}
                flexWrap={"wrap"}
              >
                <Box maxWidth={"80px"} maxHeight={"80px"}>
                  <img src={logoAsistencia} width={"80px"} height={"80px"} />
                </Box>
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#14672E",
                  }}
                >
                  ASISTENCIA
                </Typography>
                <Typography
                  style={{
                    textAlign: "justify",
                    fontSize: "1rem",
                    color: "#7D995C",
                    minHeight: "13vh",
                  }}
                >
                  API para manejar registros de asistencia en organizaciones,
                  con métodos para devolver todos los registros de acceso y una
                  lista de empleados trabajando en tiempo real, permitiendo
                  seguimiento de empleados.
                </Typography>
              </Stack>
              <Stack p={5}>
                {filter("api-asistencia").map((podData: any, index: any) => (
                  <>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: "0.9rem",
                        color: "#14672E",
                      }}
                    >
                      Estado: {podData.status}
                    </Typography>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: "0.9rem",
                        color: "#14672E",
                      }}
                    >
                      Reinicios: {podData.restartCount}
                    </Typography>
                  </>
                ))}
              </Stack>
            </Grid>
          </a>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          xl={3}
          minHeight={"40vh"}
          display={"flex"}
          justifyContent={"center"}
        >
          <a href="https://api.formosa.gob.ar/api-auth/api/">
            <Grid container>
              <Stack
                spacing={1}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                width={"100%"}
                p={5}
                flexWrap={"wrap"}
              >
                <Box maxWidth={"80px"} maxHeight={"80px"}>
                  <img src={logoApiAuth} width={"80px"} height={"80px"} />
                </Box>
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#14672E",
                  }}
                >
                  Api Auth
                </Typography>
                <Typography
                  style={{
                    textAlign: "justify",
                    fontSize: "1rem",
                    color: "#7D995C",
                    minHeight: "13vh",
                  }}
                >
                  API de autenticación para garantizar seguridad de datos en un
                  sistema, con funciones para administrar usuarios, autenticar y
                  generar tokens, gestionar registros de actividad,
                  organizaciones y clientes.
                </Typography>
              </Stack>
              <Stack p={5}>
                {filter("api-asistencia").map((podData: any, index: any) => (
                  <>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: "0.9rem",
                        color: "#14672E",
                      }}
                    >
                      Estado: {podData.status}
                    </Typography>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: "0.9rem",
                        color: "#14672E",
                      }}
                    >
                      Reinicios: {podData.restartCount}
                    </Typography>
                  </>
                ))}
              </Stack>
            </Grid>
          </a>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          xl={3}
          minHeight={"40vh"}
          display={"flex"}
          justifyContent={"center"}
        >
          <a href="https://api.formosa.gob.ar/api-firmadigital/api/">
            <Grid container>
              <Stack
                spacing={1}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                width={"100%"}
                p={5}
                flexWrap={"wrap"}
              >
                <Box maxWidth={"80px"} maxHeight={"80px"}>
                  <img src={logoFirma} width={"80px"} height={"80px"} />
                </Box>
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#14672E",
                  }}
                >
                  Firma Digital
                </Typography>
                <Typography
                  style={{
                    textAlign: "justify",
                    fontSize: "1rem",
                    color: "#7D995C",
                    minHeight: "13vh",
                  }}
                >
                  Servicio para firmar digitalmente documentos y archivos en
                  línea, con tecnología de criptografía para garantizar
                  autenticidad e integridad de los documentos. Permite enviar
                  solicitudes y recibir respuestas digitales a través de la API.
                </Typography>
              </Stack>
              <Stack p={5}>
                {filter("api-asistencia").map((podData: any, index: any) => (
                  <>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: "0.9rem",
                        color: "#14672E",
                      }}
                    >
                      Estado: {podData.status}
                    </Typography>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: "0.9rem",
                        color: "#14672E",
                      }}
                    >
                      Reinicios: {podData.restartCount}
                    </Typography>
                  </>
                ))}
              </Stack>
            </Grid>
          </a>
        </Grid>
      </Grid>
    </>
  );
};
