import img from "../img/footer_gob.svg";
import { Box, Grid } from '@mui/material';


export const FooterIcon = ({ style }:any) => {
    return (
        <Grid item
        xs={12}
        sm={6}
        md={4}
        lg={3}
        xl={3}
        minHeight={"25vh"}
        display={"flex"}
        >
            <Box sx={{justifyContent:'center', display:'flex', width:'100%', marginTop:'0%'}}>
                <Box sx={{display:'flex', width:{xs:'90%',md:'90%', lg:'90%', xl:'60%'}, }}>
                    <img src={img} style={{ height: '100%', width: '100%' }} alt={'Go back'} />
                </Box>
            </Box>
        </Grid>
    )
}