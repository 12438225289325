import Axios from "../../common/config/axios";

const endpoint = "status";

export const getApiStatusPods = async () => {
  return await Axios.get(`/${endpoint}/ApiPods`);
};

export const getApiVersion = async () => {
  try {
    const response = await Axios.get(`/${endpoint}/Deployments/api`);
    return response.data; // Devuelve solo la propiedad 'data'
  } catch (error) {
    console.error('Error fetching API version:', error);
    throw error; // Opcional: Propaga el error para manejarlo en el nivel superior
  }
};