import { Box, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { StatusList } from "../interface/pods/status";
import { ModalApi } from "../screens/Components/ModalApi";

interface Props {
  status: StatusList | any;
  version: any;
}
export const Footer = ({ status, version }: Props) => {
  return (
    <Paper
      sx={{
        width: "100%",
        position: "fixed",
        bottom: 0,
        height: { xs: "18vh", sm: "18vh", md: "9vh" },
        background: "linear-gradient(90deg, #14672E 0%, #7D995C 100%)",
      }}
      square
      variant="outlined"
    >
      <Grid container direction={"row"}>
        <Grid item xs={6} sm={6} md={6} lg={3}>
          <Typography color={"white"}>
            Total de servicios: {status.list.listMeta.totalItems}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={3}>
          <Typography color={"white"}>
            En funcionamiento: {status.list.status.running}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={3}>
          <Typography color={"white"}>
            Fallido: {status.list.status.failed}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={3}>
          <Typography color={"white"}>
            Desconocido: {status.list.status.unknown}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={3}>
          <Typography color={"white"}>
            Estado de los servicios: Activo
          </Typography>
        </Grid>

        <Grid item xs={6} sm={6} md={6} lg={3}>
          <Typography color={"white"}>
            Pendiente: {status.list.status.pending}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={3}>
          <Typography color={"white"}>
            Exitoso: {status.list.status.succeeded}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={3}>
          <Typography color={"white"}>
            Terminado: {status.list.status.terminating}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={3}>
          <ModalApi version={version} />
        </Grid>
      </Grid>
    </Paper>
  );
};
