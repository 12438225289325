import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

// Define el tipo de datos esperado para la estructura de la API
interface Deployment {
  name: string;
  creationTimestamp: string;
  daysSinceCreation: number;
  version: string;
}

interface ApiResponse {
  listMeta: {
    totalItems: number;
  };
  deployments: Deployment[];
}

// Define el tipo de props que el componente MyTable espera recibir
interface MyTableProps {
  version: ApiResponse | null; // Permitir que version pueda ser null inicialmente
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: 'background.paper',
  border: '6px solid #4c9900',
  boxShadow: 24,
  padding: 4,
};

export const ModalApi: React.FC<MyTableProps> = ({ version }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen} variant="contained" color="success">Version</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Versión</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {version && version.deployments ? (
                  version.deployments.map((deployment, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell>{deployment.name}</TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>{deployment.version}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={2}>Cargando...</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </div>
  );
};
