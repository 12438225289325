import { Container, Toolbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FooterIcon } from "../../footer/Footer";
import { Pod } from "../../interface/pods/status";
import { Footer } from "../../layout/Footer";
import { HomeLayout } from "../../layout/HomeLayout";
import { getApiStatusPods, getApiVersion } from "../../service/status/status";
import { Tabss } from "../../Tabss";
import { ItemPageWeb } from "./ItemPageWeb";

export const ListPageWeb = () => {
  const [dataStatus, setDataStatus] = useState<Pod | null>();
  const [dataVersion, setDataVersion] = useState<Pod | null>();

  const getStatusPod = async () => {
    // setIsLoading(true);
    try {
      const { data } = await getApiStatusPods();
      if (data) setDataStatus(data);
      // setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getVersionData = async () => {
    try {
      const data2 = await getApiVersion(); // Llama a la función para obtener datos
      if (data2) setDataVersion(data2);
    } catch (error) {
      console.error("Error getting version data:", error);
    }
  };

  useEffect(() => {
    getStatusPod();
    getVersionData();
  }, []);

  return (
    <>
      {dataStatus && <ItemPageWeb status={dataStatus} />}
      <FooterIcon />
      {dataStatus && <Footer status={dataStatus} version={dataVersion} />}
    </>
  );
};
