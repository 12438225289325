import { HomeLayout } from "../layout/HomeLayout";
import { ListPageWeb } from "./Components/ListPageWeb";

export const Home = () => {
    return (
        <HomeLayout>
            <ListPageWeb />
        </HomeLayout>
    )
}
