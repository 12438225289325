import { Box, AppBar, Toolbar } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import React from "react";
import '../css/banner.css'
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

interface Props {
  children?: React.ReactNode;
}

export const HomeLayout = ({ children }: Props) => {
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position={"fixed"}>
          <Toolbar
            sx={{
              background: "linear-gradient(90deg, #14672E 0%, #7D995C 100%)",
            }}
          >
            <Box display={"flex"} textAlign={"center"} width={"100%"}>
              <div className="banner__text"><b>API'S</b></div>
            </Box>
          </Toolbar>
        </AppBar>
        <Container disableGutters={true} maxWidth={false}>
          <Toolbar sx={{
            marginBottom:2
          }}/>
            {children}
        </Container>
      </Box>
    </>
  );
};
