import React from 'react';
import ReactDOM from 'react-dom/client';
import { Tabss } from './Tabss';
import {Container} from '@mui/material';
import { Navbar } from './navbar/Navbar';
import Toolbar from '@mui/material/Toolbar';
import { FooterIcon } from './footer/Footer';
import { HomeLayout } from './layout/HomeLayout';
import { App } from './App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

